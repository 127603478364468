<template>
  <div>
    <!-- Title and subtitle -->
    <p class="auth-subtitle-gray">{{ $t('REGISTER_OTHER.SUBTITLE') }}</p>
    <h1 class="auth-title">{{ $t('REGISTER_ACCOUNT') }}</h1>

    <InformationBox class="info-box">
      <template #text>
        <p class="text">{{ $t('REGISTER_OTHER.INFO') }}&nbsp;
          <router-link class="link" :to="{name: 'RegisterTeacher'}">{{ $t('REGISTER_OTHER.SUBSCRIBE_AS_TEACHER') }}</router-link>
        </p>
      </template>
    </InformationBox>

    <!-- Form -->
    <!-- TODO: Update implementation. -->
    <!-- TODO: Add error handling. -->
    <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
      <template #form-content>

        <!-- First Name -->
        <InputField :field-title="`${$t('FIRST_NAME')}`"
                    :field-name="'firstName'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('FIRST_NAME')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../../assets/icons/icn_account.svg')"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="auth-form-element"/>

        <!-- Last Name -->
        <InputField :field-title="`${$t('LAST_NAME')}`"
                    :field-name="'lastName'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('LAST_NAME')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../../assets/icons/icn_account.svg')"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    class="auth-form-element"/>

        <!-- Function -->
        <InputField :field-title="`${$t('REGISTER.FUNCTION')}`"
                    :field-name="'function'"
                    :rules="''"
                    :type="'text'"
                    :placeholder="$t('REGISTER.FUNCTION_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :input-icon="require('../../../../assets/icons/icn_job.svg')"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    :api-error-text="''"
                    :inline-subtitle="'(Optioneel)'"
                    class="auth-form-element"/>

        <!-- E-mail -->
        <InputField :field-title="`${$t('EMAIL_ADDRESS')}`"
                    :field-name="'email'"
                    :rules="'required'"
                    :type="'email'"
                    :placeholder="$t('EMAIL_ADDRESS')"
                    :cy-selector="'email-input'"
                    :api-error-text="emailApiError"
                    :input-icon="require('../../../../assets/icons/icn_email.svg')"
                    :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                    class="auth-form-element">
          <template #additionalApiErrorSlot>
            <router-link :to="{ name: 'Login', query: { email: emailInput } }">
              <span class="auth-form-element__login-with-email"
              >Inloggen met dit e-mailadres.</span>
            </router-link>
          </template>
        </InputField>

        <!-- Password field -->
        <PasswordConfirmation :field-title="`${$t('PASSWORD')}`"
                              :field-title-confirm="`${$t('REPEAT_PASSWORD')}`"
                              :placeholder="$t('PASSWORD')"
                              :placeholder-confirm="$t('REPEAT_PASSWORD')"
                              :input-icon="require('../../../../assets/icons/icn_password.svg')"
                              :input-icon-error="require('../../../../assets/icons/icn_error.svg')"
                              :show-error="false"
                              :debounce="0"
                              :is-matching="checkMatching"
                              :field-info="$t('PASSWORD_INFO')"
                              :custom-text-error-class="'text-error-right'"
                              :api-error-text="authError"
                              :display-api-error="false"
                              class="auth-form-element"/>

        <!-- Terms of use agreement -->
        <Checkbox :field-title="''"
                  :text="$t('REGISTER_TEACHER.AGREE')"
                  :field-name="'agreeToTerms'"
                  :rules="'required'">
          <template #additional-text>
            <a class="link-main" :href="promotionalWebsiteUserPolicyUrl" target="_blank">{{ $t('REGISTER_TEACHER.TERMS') }}</a>
          </template>
        </Checkbox>

        <!-- Receive instruction e-mails -->
        <Checkbox :field-title="''"
                  :text="$t('REGISTER_OTHER.INSTRUCTION_MAILS')"
                  :value="true"
                  :field-name="'instructionMails'"/>

        <!-- Receive newsletter -->
        <Checkbox :field-title="''"
                  :text="$t('REGISTER_OTHER.NEWSLETTER')"
                  :field-name="'newsletter'"/>
      </template>

      <template #button-submit>
        <vue-recaptcha 
          :sitekey="recaptchaKey"
          @verify="onRecaptchaVerified"
          class="recaptcha-container"
        ></vue-recaptcha>

        <button type="submit" class="pink-button auth-button">
          {{ $t('CREATE_ACCOUNT') }}
        </button>
      </template>

    </FormWrapper>

    <!-- Warning Emails -->
    <BasicModal v-if="isBasicVersionDisplayed" />
  </div>
</template>

<script>
import InformationBox from '../../../../components/elements/InformationBox'
import Checkbox from '../../../../components/yo-form/Checkbox'
import FormWrapper from '../../../../components/yo-form/FormWrapper'
import InputField from '../../../../components/yo-form/InputField'
import PasswordConfirmation from '../../../../components/yo-form/PasswordConfirmation'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'

import ApiHandler from '@/utils/handlers/ApiHandler'
import { SEND_TEACHER_LOGIN } from '@/store/modules/auth/actions'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ROUTE_NAMES_PROFESSIONAL } from '@/router/modules/professional'
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { SET_BASIC_MODAL_DATA } from '@/store/mutations'
import { computed } from '@vue/runtime-core'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'RegisterOther',
  components: {
    InformationBox,
    Checkbox,
    FormWrapper,
    InputField,
    PasswordConfirmation,
    BasicModal,
    VueRecaptcha
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const emailApiError = ref('')
    const emailInput = ref('')
    const { t } = useI18n()

    const promotionalWebsiteUserPolicyUrl = `${process.env.VUE_APP_PROMOTIONAL_WEBSITE_URL}/${t('ROUTE.PROMOTIONAL_WEBSITE_USER_POLICY')}`

    const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY
    let recaptchaResponse = ref('')

    const isMatching = ref(false)

    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.WARNING_EMAILS
    )

    function checkMatching(value) {
      isMatching.value = value
    }

    function onRecaptchaVerified(response) {
      recaptchaResponse.value = response
    }

    // Form
    function validateData(data) {
      if (!isMatching.value) {
        return
      }
      if (data.instructionMails) {
        registerOther(data)
      } else {
        const payload = {
          modalOptions: {
            modalContent: BASIC_MODAL_CONTENT.WARNING_EMAILS,
            cancelCallback: null,
            executeCallback: () => registerOther(data),
            data: data
          },
          activeModal: MODAL_NAMES.WARNING_EMAILS
        }
        store.commit(SET_BASIC_MODAL_DATA, payload)
      }
    }

    function registerOther(data) {
      const formData = new FormData()
      emailInput.value = data.email
      formData.append('firstName', data.firstName)
      formData.append('lastName', data.lastName)
      formData.append('email', data.email)
      formData.append('password', data.password)
      formData.append('receiveNewsletterGeneral', data.instructionMails ?? false)
      formData.append('receiveNewsletterWeekMedia', data.newsletter ?? false)
      formData.append('jobTitle', data.function)
      formData.append('recaptchaResponse', recaptchaResponse.value)

      ApiHandler.post('auth/professional/register', formData).then(() => {
        //TODO replace with going to email verification page
        modalController.value.resetActiveModal()
        router.push({ name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT, query: { email: data.email, professional: true } })
      }).catch((response) => {
        const error = response.response.data.error
        switch (error) {
          case 'email_already_in_use':
            emailApiError.value = t('API_ERRORS.EMAIL_ALREADY_IN_USE')
            break
        }
      })
    }

    return {
      // Form
      authError: '',
      emailApiError,
      checkMatching,
      promotionalWebsiteUserPolicyUrl,

      recaptchaKey,
      onRecaptchaVerified,

      // Form
      validateData,
      emailInput,
      isBasicVersionDisplayed
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.auth-subtitle-gray {
  text-transform: uppercase;
}

.info-box {
  margin-bottom: rem(20);
  margin-top: rem(15);
}

.auth-form-element__login-with-email {
  color: var(--blue_link);
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}
</style>
